import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1170px;

  padding: 25px 75px;
  color: ${colors.primary.gray};
  background-color: ${colors.primary.white};
  z-index: 1;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  @media (max-width: ${breakPoints.tabletPortrait}) {
    flex-direction: column-reverse;
    gap: 25px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  gap: 100px;
  @media (max-width: ${breakPoints.tablet}) {
    flex-direction: column;
    gap: 25px;
  }
  a {
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

export const LinkGroup = styled.div`
  display: flex;

  align-items: center;
  gap: 25px;
  row-gap: 10px;
  @media (max-width: ${breakPoints.mobile}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
