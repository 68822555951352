'use client';
import React, { useState, useEffect } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { TypeAnimation } from 'react-type-animation';
import * as Text from '@/ui/Text';
import FadeTransition from './FadeTransition';
import DataTypes from '../DataTypes';
import { Stack, Row } from '@/ui/primatives';
import * as Styled from './styled';
import Link from 'next/link';
import Button from '@/ui/Button';

const TypeTransition: React.FC<{ textArray: string[] }> = ({ textArray }) => {
  const waitInterval = 2500;
  return (
    <Text.Animated>
      <TypeAnimation
        preRenderFirstString
        sequence={textArray.map((t) => [t, waitInterval]).flat()}
        wrapper="span"
        speed={50}
        deletionSpeed={80}
        // omitDeletionAnimation
        repeat={Infinity}
      />
    </Text.Animated>
  );
};

const ScrollTransition: React.FC<{ textArray: string[]; interval: number }> = ({ textArray, interval = 5000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), interval);
    return () => clearTimeout(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TextTransition inline springConfig={presets.gentle}>
      <Text.Animated>
        <span style={{ whiteSpace: 'nowrap' }}>{textArray[index % textArray.length]}</span>
      </Text.Animated>
    </TextTransition>
  );
};

const Hero: React.FC<{ transitionMethod: 'type' | 'scroll' | 'fade' }> = ({ transitionMethod = 'scroll' }) => {
  const TEXT_OPTIONS = ['World-Class', 'Differentiated', 'Unstructured', 'Structured'];
  return (
    <Styled.HeroWrapper>
      <Styled.Hero>
        <h2>
          Build{' '}
          {transitionMethod === 'scroll' ? (
            <ScrollTransition textArray={TEXT_OPTIONS} interval={3000} />
          ) : transitionMethod === 'type' ? (
            <TypeTransition textArray={TEXT_OPTIONS} />
          ) : (
            <FadeTransition textArray={TEXT_OPTIONS} />
          )}
          <br />
          AI Datasets. <Text.Animated>Together.</Text.Animated>
        </h2>
        <Styled.HeroContent>
          <span>
            Open-source tools to track, iterate, collaborate on, and discover multi-modal data in <i>any</i> format.
          </span>
        </Styled.HeroContent>
        <DataTypes />
        <br />

        <Link href="/register">
          <Button>GET STARTED</Button>
        </Link>
      </Styled.Hero>
    </Styled.HeroWrapper>
  );
};

export default Hero;
