'use client';
import styled from 'styled-components';
import { colors } from '@/styles';

export const ContentBlock = styled.div``;

export const CodeBlock = styled.div`
  /* background: ${colors.secondary.lightGray1}; */
  & > pre {
    padding: 20px 10px !important;
    /* border: 1px solid ${colors.secondary.lightGray2}; */
    background: ${colors.secondary.lightGray1} !important;
  }
`;
