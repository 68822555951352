'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${colors.primary.white};
`;

export const LayoutContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.secondary.lightGray2};
  min-height: 100vh;
`;
