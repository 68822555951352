'use client';
import React from 'react';
import * as Styled from './styled';
import { useModal } from 'react-hooks-use-modal';
import { ROOT_LAYOUT_DOM_NODE_ID } from '@/config/appSettings';
import * as Icon from '@/ui/Icon';

const IntroVideo2: React.FC<{}> = () => {
  const [Modal, open, close, isOpen] = useModal(ROOT_LAYOUT_DOM_NODE_ID, {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  });
  return (
    <Styled.IntroVideo>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src="/assets/images/homepage/hero-screen.png" alt="Hero Video" />
      {!isOpen && (
        <Styled.PlayButton onClick={open}>
          <span></span>
        </Styled.PlayButton>
      )}
      {isOpen && (
        <Modal>
          <Styled.ContentWrapper>
            <Styled.CloseButton onClick={close}>
              <Icon.X />
            </Styled.CloseButton>
            <iframe
              width="100%"
              height="100%"
              src="https://www.loom.com/embed/920ad951497f470b89d5ce647f218f3a?sid=a8dc180c-7e62-4d8e-b8e3-72457ab5eb82?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
              frameBorder="0"
              allowFullScreen
              onClick={(e) => e.stopPropagation()}
            ></iframe>
          </Styled.ContentWrapper>
        </Modal>
      )}
    </Styled.IntroVideo>
  );
};

export default IntroVideo2;
