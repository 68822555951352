'use client';
// @ts-ignore
import Terminal from 'react-animated-term'; // Not sure why this is throwing an error..
import 'react-animated-term/dist/react-animated-term.css';

import * as Styled from './styled';

const TerminalAnimation: React.FC<{}> = () => {
  const spinner = [];
  for (let i = 1; i <= 20; i++) {
    spinner.push('🌾'.repeat(i - 1) + '🐂');
  }

  return (
    <Styled.TerminalWrapper>
      <Terminal
        white
        lines={[
          {
            text: 'brew tap Oxen-AI/oxen',
            cmd: true,
          },
          {
            text: 'brew install oxen',
            cmd: true,
          },
          {
            text: 'oxen clone https://hub.oxen.ai/ox/CatDogBBox',
            cmd: true,
          },
          {
            text: '🐂 Downloading 100.2 MB',
            cmd: false,
          },
          {
            text: '🎉 Cloned to CatDogBBox/',
            cmd: false,
            frames: spinner.map(function (spinner) {
              return {
                text: spinner,
                delay: 60,
              };
            }),
          },
        ]}
        interval={20}
      />
    </Styled.TerminalWrapper>
  );
};

export default TerminalAnimation;
