'use client';
import React, { useState, useEffect } from 'react';
import * as Text from '@/ui/Text';
import * as Styled from './styled';

const FadeTransition: React.FC<{ textArray: string[] }> = ({ textArray }) => {
  const FADE_INTERVAL_MS = 1500;
  const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2;
  const WORDS_TO_ANIMATE = textArray;

  type FadeProp = { fade: 'fade-in' | 'fade-out' };
  const [fadeProp, setFadeProp] = useState<FadeProp>({ fade: 'fade-in' });
  const [wordOrder, setWordOrder] = useState(0);

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === 'fade-in' ? setFadeProp({ fade: 'fade-out' }) : setFadeProp({ fade: 'fade-in' });
    }, FADE_INTERVAL_MS);

    return () => clearInterval(fadeTimeout);
  }, [fadeProp]);

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder((prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length);
    }, WORD_CHANGE_INTERVAL_MS);

    return () => clearInterval(wordTimeout);
  }, [WORDS_TO_ANIMATE, WORD_CHANGE_INTERVAL_MS]);

  return (
    <Styled.FadeTransitionSpan className={fadeProp.fade}>
      <Text.Animated>{WORDS_TO_ANIMATE[wordOrder]}</Text.Animated>
    </Styled.FadeTransitionSpan>
  );
};

export default FadeTransition;
