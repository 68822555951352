'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const SocialProof = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-width: ${breakPoints.mobile}) {
    h6 {
      text-align: center;
    }
  }
`;
