'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const PageLayout = styled.div`
  flex: 1;
  display: flex;

  main {
    flex: 1;
    padding: 25px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    padding: 15px 10px;
    width: 100%;
  }

  @media (max-width: ${breakPoints.mobile}) {
    padding: 15px 10px;
    width: 100%;
  }
`;

export const Container = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 50px;
  gap: 25px;

  @media (max-width: ${breakPoints.tablet}) {
    max-width: auto;
    padding: 10px;
  }

  @media (max-width: ${breakPoints.mobile}) {
    max-width: auto;
    padding: 15px 10px;
  }
`;

export const LeftSideBar = styled.div`
  background-color: ${colors.secondary.lightGray1};
  min-width: 384px;
  max-width: 384px;
  padding: 35px 25px;

  @media (max-width: ${breakPoints.tablet}) {
    display: none;
  }

  @media (max-width: ${breakPoints.mobile}) {
    display: none;
  }
`;
