import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const RepositoryCard = styled.div`
  /* border: 1px solid ${colors.secondary.lightGray3}; */
  background-color: ${colors.secondary.lightGray1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  min-height: 360px;
  padding: 20px;
  gap: 20px;
  /* aspect-ratio: 4/5; */

  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardHeader = styled.div`
  flex: 0;
  p {
    height: 2lh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardFooter = styled.div`
  flex: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 12px;
`;

export const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
