// Note: This is the main page for the marketing site
import colors from '@/styles/colors';
import { TEntryDataType } from '@/types';
import * as Icon from '@/ui/Icon';
import Tag from '@/ui/Tag';
import { Row } from '@/ui/primatives';

const DataTypeIcon: React.FC<{ dataType: TEntryDataType }> = ({ dataType }) => {
  {
    switch (dataType) {
      case 'image':
        return <Icon.Image />;
      case 'video':
        return <Icon.Video />;
      case 'text':
        return <Icon.Text />;
      case 'audio':
        return <Icon.Audio />;
      case 'tabular':
        return <Icon.Table />;
      case 'binary':
        return <Icon.Binary />;
      default:
        return <Icon.QuestionCircle />;
    }
  }
};

const DataTypeTag: React.FC<{ dataType: TEntryDataType; children: React.ReactNode }> = ({ dataType, children }) => {
  const backgroundColor = colors.dataType[dataType];
  return (
    <Tag key={dataType} small variant="default" style={{ background: backgroundColor }} title={dataType}>
      <DataTypeIcon dataType={dataType} />
      <span>{children}</span>
    </Tag>
  );
};

const DataTypes: React.FC<{}> = () => {
  return (
    <Row gap={10} alignItems="center" style={{ flexWrap: 'wrap' }}>
      <DataTypeTag dataType="image">Image</DataTypeTag>
      <DataTypeTag dataType="audio">Audio</DataTypeTag>
      <DataTypeTag dataType="video">Video</DataTypeTag>
      <DataTypeTag dataType="tabular">Tabular</DataTypeTag>
      <DataTypeTag dataType="text">Text</DataTypeTag>
      <DataTypeTag dataType="binary">More...</DataTypeTag>
    </Row>
  );
};

export default DataTypes;
