'use client';
import styled, { css } from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const GradientBlob = styled.div<{ $left?: string; $top?: string }>`
  z-index: 0;
  position: absolute;
  /* position: relative; */
  /* overflow: hidden; */
  /* width: 40vw; */
  /* height: 40vw; */
  left: ${({ $left }) => `${$left || '60vw'}`};
  top: ${({ $top }) => `${$top || '15vh'}`};
  display: flex;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* This is just to transition when you change the viewport size. */
  transition: all 0.25s ease-out;
`;

const gradientAnimation = css`
  animation: rotate var(--speed) var(--easing) alternate infinite;
`;

export const Gradient = styled.div<{ $size: number; $animate: boolean }>`
  --base-size: ${({ $size }) => `${$size}px`};
  --size: var(--base-size);
  --speed: 12s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));

  background-image: linear-gradient(#f87a7a, #f9947d, #f57ab1, #e07de8, #dbb2ed, #efb4c9);

  /* animation: rotate var(--speed) var(--easing) alternate infinite; */
  ${({ $animate }) => ($animate ? gradientAnimation : null)}
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;

  @media (min-width: 720px) {
    --speed: 6s;
    --size: calc(var(--base-size) * 1.43);
  }
`;
