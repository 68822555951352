'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const FeaturedContent = styled.div<{ $landscape: boolean }>`
  display: flex;
  flex-direction: ${({ $landscape }) => ($landscape ? 'column' : 'row')};
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  /* padding-top: 50px; */

  & > * {
    flex: 1;
    display: contents;
  }

  @media (max-width: ${breakPoints.smallDesktop}) {
    padding: 50px 0 0;
  }
  @media (max-width: ${breakPoints.mobile}) {
    h2 {
      font-size: 36px;
    }
    padding: 20px 0 0;
    gap: 20px;
  }
`;

export const FeaturedContentItem = styled.div<{ $landscape: boolean }>`
  display: flex;
  flex-direction: ${({ $landscape }) => ($landscape ? 'row' : 'column')};
  border: 1px solid ${colors.secondary.lightGray3};
  border-radius: ${({ $landscape }) => ($landscape ? '5px' : '10px')};
  max-width: 100%;
  background: ${colors.primary.white};
  justify-content: stretch;

  img {
    border-radius: ${({ $landscape }) => ($landscape ? ' 10px 0 0 10px' : ' 10px 10px 0 0')};
    border-bottom: ${({ $landscape }) => ($landscape ? 'none' : `1px solid ${colors.secondary.lightGray3}`)};
    border-right: ${({ $landscape }) => ($landscape ? `1px solid ${colors.secondary.lightGray3}` : 'none')};
    height: ${({ $landscape }) => ($landscape ? '100%' : '185px')};
    object-fit: ${({ $landscape }) => ($landscape ? 'cover' : 'contain')};
  }

  p {
    height: ${({ $landscape }) => ($landscape ? '3lh' : '5lh')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
    img {
      height: 185px;
      object-fit: contain;
    }
  }
`;

export const ImageWraper = styled.div`
  flex: 0;
  min-width: 250px;

  img {
    /* border-radius: 10px 10px 0 0; */
    width: 100%;
    /* min-width: 200px; */
    /* height: 185px; */
    /* object-fit: contain; */
    background: white;
    /* border-bottom: 1px solid ${colors.secondary.lightGray3}; */
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
  padding: 20px;
`;

export const Content = styled.div`
  flex: 1;
  h5 {
    /* padding: 0 20px; */
  }
  /* p {
    height: 5lh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 0;
  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.secondary.lightGray3};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  h6 {
    color: ${colors.primary.white};
    font-size: 1.2rem;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  background: ${colors.semantic.information.light};
  color: ${colors.semantic.information.dark};
  font-size: 0.8rem;
  padding: 5px 10px;
  white-space: nowrap;
`;
