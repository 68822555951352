import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const FadeTransitionSpan = styled.span`
  &.fade-in {
    transition: opacity 1.5s ease;
  }

  &.fade-out {
    opacity: 0;
    transition: opacity 1.5s ease;
  }
`;
