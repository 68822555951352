'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const HomePage = styled.div`
  position: relative;
  overflow-x: hidden;

  p {
    max-width: 40em;
  }

  .home-page-gradient-blob {
    @media (max-width: ${breakPoints.mobilePortrait}) {
      opacity: 0.5;
    }
  }
`;

export const HeroSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 50px;

  margin-top: 100px;
  margin-bottom: 50px;
  min-height: 400px;
  z-index: 1;

  & > div {
    flex: 1;
  }

  @media (max-width: ${breakPoints.mobilePortrait}) {
    margin-top: 50px;
    flex-direction: column;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const BorderedFullWidthSection = styled.div`
  z-index: 1;
  background: rgba(255, 255, 255, 0);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;
