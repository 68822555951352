import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Accordion = styled.div`
  --border-color: ${colors.secondary.mediumGray};
  --active-question-background: ${colors.secondary.lightGray2};
  --answer-background: ${colors.primary.white};

  border-radius: 5px;
  border: 1px solid var(--border-color);
  overflow: hidden;
`;

export const AccordionItem = styled.div`
  border-bottom: 1px solid var(--border-color);
  overflow: hidden;
  &:last-child {
    border-bottom: none;
  }
`;

export const Question = styled.button`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  &:focus {
    font-weight: 600;
  }

  &.active {
    background: var(--active-question-background);
  }
  &:hover {
    background: var(--active-question-background);
  }
  .arrow {
    font-size: 1.5rem;
    transition: 0.25s ease-in-out;
    &.active {
      rotate: 180deg;
    }
  }
`;

export const AnswerContainer = styled.div`
  padding: 0 1rem;
  transition: height 0.25s ease-in-out;
  background: var(--answer-background);
  border-radius: 5px;
`;

export const AnswerContent = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  line-height: 1.5;
`;
