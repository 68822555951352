import * as Styled from './styled';

const Text: React.FC<{ children: React.ReactNode; as?: string; style?: React.CSSProperties }> = ({
  children,
  as = 'span',
  style,
}) => {
  return (
    <Styled.Text
      style={{
        ...style,
      }}
      as={as}
    >
      {children}
    </Styled.Text>
  );
};

export const Animated: React.FC<{ children: React.ReactNode; as?: string; style?: React.CSSProperties }> = ({
  children,
  as = 'span',
  style,
}) => {
  return (
    <Styled.Animated
      style={{
        ...style,
      }}
      as={as}
    >
      {children}
    </Styled.Animated>
  );
};

export default Object.assign(Text, { Animated });
