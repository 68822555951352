'use client';
import React, { useState } from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import { DotButton, useDotButton } from './DotButton';
import { PrevButton, NextButton, usePrevNextButtons } from './ArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import * as Styled from './styled';
import { IRepository } from '@/types';

import RepositoryCard from './RepositoryCard';

type PropType = {
  slides: React.ReactNode[];
  options?: EmblaOptionsType;
  autoPlay?: boolean;
};

const Carousel: React.FC<PropType> = (props) => {
  const { slides, options, autoPlay } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay({ playOnInit: autoPlay, delay: 5000 })]);
  const [isPlaying, setIsPlaying] = useState(true);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  return (
    <Styled.Carousel $fadeSides>
      <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      <Styled.Viewport ref={emblaRef}>
        <Styled.Container>
          {slides.map((slide, index) => (
            <Styled.SlideWrapper key={index}>{slide}</Styled.SlideWrapper>
          ))}
        </Styled.Container>
      </Styled.Viewport>
      <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />

      <Styled.Controls>
        <Styled.Dots>
          {scrollSnaps.map((_, index) => (
            <DotButton key={index} onClick={() => onDotButtonClick(index)} selected={index === selectedIndex} />
          ))}
        </Styled.Dots>
      </Styled.Controls>
    </Styled.Carousel>
  );
};

const RepositorySlide: React.FC<{ repository: IRepository }> = ({ repository }) => {
  return (
    <Styled.Slide>
      <RepositoryCard repository={repository} />
    </Styled.Slide>
  );
};

const DatasetCarousel: React.FC<{ repositories: IRepository[]; autoPlay?: boolean }> = ({ repositories, autoPlay }) => {
  const OPTIONS: EmblaOptionsType = { align: 'center', loop: true };
  const SLIDE_COUNT = 6;
  // const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
  const SLIDES = repositories.map((repository) => <RepositorySlide key={repository.id} repository={repository} />);
  return <Carousel slides={SLIDES} options={OPTIONS} autoPlay={autoPlay} />;
};

export default DatasetCarousel;
