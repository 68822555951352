import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Carousel = styled.section<{ $fadeSides?: boolean }>`
  position: relative;
  width: 100%;
  margin: auto;

  --text-body: rgb(54, 49, 61);
  --detail-high-contrast-rgb-value: 192, 192, 192;
  --detail-high-contrast: rgb(192, 192, 192);
  --detail-medium-contrast: rgb(234, 234, 234);
  --text-high-contrast-rgb-value: 49, 49, 49;
  --controls-height: 3em;
  --button-height: 3.6rem;

  --slide-height: 100%;

  --slide-spacing: 1rem;
  --slide-spacing-sm: 1.6rem;
  --slide-spacing-lg: 2rem;

  --max-slide-size: 320px;
  --min-slide-size: 310px;

  --slide-size: 100%;
  --slide-size-sm: 50%;
  --slide-size-lg: calc(100% / 2);

  --nav-button-offset: ${(props) =>
    props.$fadeSides ? 'calc(var(--button-height) / 2 * -1)' : 'calc(var(--button-height) * -1)'};

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    display: ${(props) => (props.$fadeSides ? 'block' : 'none')};
    @media (max-width: ${breakPoints.mobile}) {
      display: none;
    }
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, white, transparent);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, white, transparent);
  }
`;

export const Viewport = styled.div`
  overflow: hidden;
`;

export const Container = styled.div`
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
  @media (min-width: ${breakPoints.mobilePortrait}) {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
  @media (min-width: 1200px) {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
`;

export const SlideWrapper = styled.div`
  min-width: 0;
  flex: 0 0 var(--slide-size-lg);
  /* max-width: var(--max-slide-size); */
  min-width: var(--min-slide-size);
  padding-left: var(--slide-spacing-lg);

  @media (max-width: ${breakPoints.mobilePortrait}) {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
  @media (max-width: ${breakPoints.mobile}) {
    flex: 0 0 var(--slide-size);
    padding-left: var(--slide-spacing);
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--controls-height);
`;

export const Slide = styled.div`
  border: 1px solid ${colors.secondary.lightGray3};
  border-radius: 10px;
  /* margin: 0 20px; */
  height: var(--slide-height);
`;

const PrevNextButton = styled.button`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background: radial-gradient(circle, white 1%, transparent 75%);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: var(--button-height);
  height: var(--button-height);
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    color: var(--detail-high-contrast);
  }

  svg {
    width: 35%;
    height: 35%;
  }
  @media (max-width: ${breakPoints.mobile}) {
    display: none;
  }
`;

export const PrevButton = styled(PrevNextButton)`
  position: absolute;
  top: calc((50% - var(--button-height) / 2) - var(--controls-height) / 2);
  left: var(--nav-button-offset);
  z-index: 2;
`;

export const NextButton = styled(PrevNextButton)`
  position: absolute;
  top: calc((50% - var(--button-height) / 2) - var(--controls-height) / 2);
  right: var(--nav-button-offset);
  z-index: 2;
`;

export const Dots = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
`;

export const Dot = styled.button<{ $selected?: boolean }>`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:after {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
    box-shadow: ${(props) =>
      props.$selected ? 'inset 0 0 0 0.05rem var(--text-body)' : 'inset 0 0 0 0.05rem var(--text-body)'};
    background: ${(props) => (props.$selected ? 'var(--text-body)' : 'transparent')};
  }
`;
