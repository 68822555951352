import { colors } from '@/styles';
import * as Styled from './styled';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode, googlecode, github, githubGist, railscasts } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { PortableText } from '@portabletext/react';
import Image from '../Image';

const defaults = { nonTextBehavior: 'remove' };

const blocksToText = (blocks: any, opts = {}) => {
  const options = Object.assign({}, defaults, opts);
  return blocks
    ?.map((block: any) => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`;
      }

      return block.children.map((child: any) => child.text).join('');
    })
    .join('\n\n');
};

const CodeComponent = ({ value }: { value: any }) => {
  return (
    <Styled.CodeBlock>
      <SyntaxHighlighter
        showLineNumbers
        lineNumberContainerStyle={{ paddingRight: 20 }}
        lineNumberStyle={{ color: colors.secondary.lightGray3 }}
        language={value.language}
        style={githubGist}
      >
        {value.code}
      </SyntaxHighlighter>
    </Styled.CodeBlock>
  );
};

export const TruncatedContentText: React.FC<{ content: any; length?: number; asTag?: keyof JSX.IntrinsicElements }> = ({
  content,
  length,
  asTag = 'p',
}) => {
  const CustomTag = asTag;
  const truncateLength = length || content.length;
  if (!content) {
    return null;
  }
  return (
    <CustomTag>
      {content.slice(0, truncateLength)}
      {content.length > truncateLength && '...'}
    </CustomTag>
  );
};

const components = {
  types: {
    image: Image,
    code: CodeComponent,
    // Any other custom types you have in your content
    // Examples: mapLocation, contactForm, code, featuredProjects, latestNews, etc.
  },
};

const ContentBlock: React.FC<{ content: any }> = ({ content }) => (
  <Styled.ContentBlock>
    <PortableText value={content} components={components} />
  </Styled.ContentBlock>
);

export default ContentBlock;
