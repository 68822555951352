import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const PlayButton = styled.a`
  --pulse-size: 60px;
  --button-size: 50px;
  --button-color: #f87a7a;
  --pulse-color: #dbb2ed;

  /* background-image: linear-gradient(#f87a7a, #f9947d, #f57ab1, #e07de8, #dbb2ed, #efb4c9); */

  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: var(--pulse-size);
    height: var(--pulse-size);
    background: var(--pulse-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: var(--button-size);
    height: var(--button-size);
    background: var(--button-color);
    border-radius: 50%;
    transition: all 200ms ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.35);
  }

  img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    transform: translateX(4px) translateY(10px);
    border-left: 22px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }

  &:hover:after {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
`;

export const IntroVideo = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 400px;
  max-width: 50%;
  position: relative;

  border: 1px solid ${colors.secondary.lightGray3};
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);

  img {
    display: block;
    object-position: top left;
    width: 100%;
    min-height: 400px;
    object-fit: cover;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  padding: 10px;
  cursor: pointer;
  outline: none;
  svg {
    stroke-width: 2;
  }
  @media (min-width: ${breakPoints.tablet}) {
    /* HACK: must be 'visible' on the page to prevent focus trap from breaking */
    opacity: 0;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 1050px;
  height: 90vh;
  max-height: 675px;
  display: flex;
  overflow: visible;
`;
