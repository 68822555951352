import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    display: block;
    justify-content: flex-start;
    gap: 1rem;
    line-height: 125%;
    font-weight: 600;
  }
  @media (max-width: ${breakPoints.tablet}) {
    h2 {
      font-size: 40px;
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    h2 {
      font-size: 34px;
    }
    max-width: 100%;
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 0;
`;

export const HeroContent = styled.div`
  max-width: 50vw;
  font-size: 1.25rem;
  font-weight: 500;
  @media (max-width: ${breakPoints.mobile}) {
    max-width: 100%;
  }
`;
