'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const TerminalWrapper = styled.div`
  height: 180px;
  /* margin: 40% 20px 40% 0; */
  .Terminal-window-white {
    background: rgba(255, 255, 255, 0.75);
    .Terminal-prompt {
      color: cornflowerblue;
    }
  }
  @media (max-width: ${breakPoints.tablet}) {
    /* margin: 20px; */
  }
`;
