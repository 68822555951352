'use client';
import styled from 'styled-components';
import { colors, breakPoints, mixins } from '@/styles';

export const Text = styled.span``;

export const Animated = styled.span`
  display: inline-block;
  white-space: pre-wrap;
  ${mixins.animatedGradientText()}
`;
