import React, { useRef, useState } from 'react';
import * as Icon from '@/ui/Icon';
import * as Styled from './styled';
import ContentBlock from '@/components/Content/ContentBlock';

export type TAccodionData = [
  {
    question: string;
    answer: string;
  },
];

const Accordion: React.FC<{ data: TAccodionData }> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Styled.Accordion>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </Styled.Accordion>
  );
};

const AccordionItem: React.FC<{ question: string; answer: any; isOpen: boolean; onClick: () => void }> = ({
  question,
  answer,
  isOpen,
  onClick,
}) => {
  const contentHeight = useRef<HTMLDivElement>(null);
  return (
    <Styled.AccordionItem>
      <Styled.Question className={`${isOpen ? 'active' : ''}`} onClick={onClick}>
        <div>{question}</div>
        <Icon.Down className={`arrow ${isOpen ? 'active' : ''}`} />
      </Styled.Question>

      <Styled.AnswerContainer
        ref={contentHeight}
        style={isOpen ? { height: contentHeight?.current?.scrollHeight as number } : { height: '0px' }}
      >
        <Styled.AnswerContent>
          {typeof answer === 'string' ? <span>{answer}</span> : <ContentBlock content={answer} />}
        </Styled.AnswerContent>
      </Styled.AnswerContainer>
    </Styled.AccordionItem>
  );
};

export default Accordion;
