import { getImageDimensions } from '@sanity/asset-utils';
import { urlForImage } from '@/sanity/lib/image';

// Barebones lazy-loaded image component
const Image = ({
  value,
  alt,
  isInline,
  style,
}: {
  value: any;
  alt?: string;
  isInline?: boolean;
  style?: React.CSSProperties;
}) => {
  const { width, height } = getImageDimensions(value);
  const url = urlForImage(value);
  console.log('url', url);
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={url}
      alt={alt || value.alt || ' '}
      loading="lazy"
      style={{
        ...style,
        // Display alongside text if image appears inside a block text span
        display: isInline ? 'inline-block' : 'block',
        // Avoid jumping around with aspect-ratio CSS property
        aspectRatio: width / height,
      }}
    />
  );
};

export default Image;
